<template>
  <div class="breadcrumb scroll-hidden">
    <i class="el-icon-s-home"></i>
    <span v-for="(l, i) in list" :key="i">
      <i @click="handleBreadcrumb(l, i)">{{ l.text }}</i
      ><em>></em>
    </span>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    handleBreadcrumb(item, index) {
      if (index === this.list.length - 1) {
        return;
      }
      // console.log(item);
      this.$router.push({
        name: item.name,
        query: item.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
}
@media only screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb {
  height: 46px;
  line-height: 46px;
  background: #5da8f3;
  padding: 0 24% 0 2%;
  overflow-x: auto;
  white-space: nowrap;

  span {
    font-size: 14px;
    margin-left: 6px;
    i,
    em {
      font-style: normal;
    }
    i {
      cursor: pointer;
      margin-right: 6px;
      font-weight: 700;
      word-break: keep-all;
    }

    &:last-child {
      i {
        cursor: default;
      }
      em {
        display: none;
      }
    }
  }
}
</style>