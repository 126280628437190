<template>
  <div class="module">
    <el-row>
      <el-col :xs="0" :sm="7" class="module-left">
        <div class="title">
          <div class="title-box">{{ moduleName }}</div>
        </div>
        <div
          class="sub-menu-item"
          :class="{ active: s.id === Number(id) }"
          v-for="s in subModuleMenuList"
          :key="s.id"
          @click="changeSubMenu(s)"
        >
          <div class="item-box">
            <img :src="baseUrl + (s.type_img && s.type_img.file_path)" alt="" />
            <div class="text-box">
              <p class="p1">{{ s.type_name }}</p>
              <!-- <p class="p2" v-show="s.type_name !== s.type_title">
                {{ s.type_title }}
              </p> -->
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="17" class="module-right">
        <Breadcrumb :list="breadcrumbList" />
        <div class="item-title scroll-hidden" v-show="!cId && menuTitle">
          <i class="el-icon-chat-dot-round"></i>{{ menuTitle }}
        </div>
        <router-view @clickItem="handleClick" class="main" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Breadcrumb from "_c/Breadcrumb.vue";
export default {
  name: "Module",
  components: {
    Breadcrumb,
  },
  data() {
    return {
      module_code: "",
      id: "",
      cId: "",
      subModuleMenuList: [],
      breadcrumbList: [],
      menuTitle: "",
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log("$route");
        const { query } = val;
        this.module_code = query.module_code;
        this.id = query.id;
        this.cId = query.cid;

        if (!query.cid) {
          this.getBreadcrumbList();
        }
      },
    },
    // 点击菜单切换module_code会触发
    module_code() {
      console.log("module_code");
      let item = this.moduleMenuList.find(
        (item) => item.module_code === this.module_code
      );
      this.$store.commit("CHANGE_SETTING", {
        key: "moduleName",
        value: item.module_name,
      });
      this.subModuleMenuList = item.typelist;

      this.getBreadcrumbList();
    },
    // 刷新会触发
    moduleMenuList: {
      deep: true,
      handler(val) {
        console.log("moduleMenuList");
        let item = val.find((item) => item.module_code === this.module_code);
        this.$store.commit("CHANGE_SETTING", {
          key: "moduleName",
          value: item.module_name,
        });
        this.subModuleMenuList = item.typelist;

        this.getBreadcrumbList();
      },
    },

    // defaultActive(val) {
    //   const moduleCode = val.split("-")[0];
    //   const id = val.split("-")[1];
    //   if (this.moduleMenuList.length) {
    //     let item = this.moduleMenuList.find(
    //       (item) => item.module_code === moduleCode
    //     );
    //     this.subModuleMenuList = item.typelist;
    //   }
    //   this.module_code = moduleCode;
    //   this.id = id;
    // },
  },
  created() {
    // 执行一次
    if (this.moduleMenuList.length) {
      let item = this.moduleMenuList.find(
        (item) => item.module_code === this.module_code
      );
      this.$store.commit("CHANGE_SETTING", {
        key: "moduleName",
        value: item.module_name,
      });
      this.subModuleMenuList = item && item.typelist;
      this.getBreadcrumbList();
    }
  },
  mounted() {},
  methods: {
    // 点击切换左边子菜单
    changeSubMenu(item) {
      let name = this.matchName(item);
      this.$router.push({
        name,
        query: { module_code: item.module_code, id: item.id },
      });
      this.getBreadcrumbList();
    },
    // 获取右边面包屑
    getBreadcrumbList() {
      if (this.subModuleMenuList && this.subModuleMenuList.length) {
        let arr = [{ name: "Home", text: "首页", query: {} }]; // 默认首页
        let secName = this.matchName(this.subModuleMenuList[0]);
        let secObj = {
          text: this.moduleName,
          name: secName,
          query: {
            module_code: this.module_code,
            id: this.subModuleMenuList[0].id,
          },
        }; // 第二级
        let targetItem = this.subModuleMenuList.find(
          (el) => el.id === Number(this.id)
        );

        arr.push(secObj);

        if (targetItem) {
          // this.menuTitle = targetItem.type_title || "--";
          this.menuTitle = targetItem.type_mes;

          let trdObj = {
            text: targetItem.type_name,
            name: this.$route.name,
            query: {
              module_code: this.module_code,
              id: this.id,
            },
          };

          arr.push(trdObj);
        }
        this.breadcrumbList = arr;
      }
    },
    handleClick(name) {
      if (this.breadcrumbList.length < 4) {
        this.breadcrumbList.push({
          text: name,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #0981e4;

@media only screen and (min-width: 769px) {
  .module {
    height: 100vh;
    margin: 16px 0;
  }

  .main {
    max-height: calc(100% - 46px - 30px);
    padding: 0 24% 0 2%;
  }

  .item-title {
    height: 30px;
    line-height: 30px;
    padding: 0 24% 0 2%;
    // margin-top: 10px;

    i {
      margin-right: 6px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .module {
    height: calc(100vh - 60px - 56px);
    overflow-y: auto;
  }

  .main {
    max-height: calc(100% - 30px);
    padding: 0 5px;
  }

  .item-title {
    height: 30px;
    line-height: 30px;
    padding: 0 2% 0 2%;
    white-space: nowrap;
    overflow: auto;
    // margin-top: 10px;

    i {
      margin-right: 6px;
    }
  }
}

.module {
  > .el-row {
    height: 100%;
  }
  .module-left {
    height: 100%;
    background: $mainColor;

    .title {
      height: 46px;
      line-height: 46px;
      display: flex;
      justify-content: flex-end;
      .title-box {
        width: 40%;
        color: #26310b;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
      }
    }
    .sub-menu-item {
      height: 96px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6px;
      &:hover {
        background: #5da8f3;
      }
      &.active {
        background: #5da8f3;
      }
      .item-box {
        width: 40%;
        height: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          z-index: 1;
        }
        .text-box {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #fff;
        }
        p {
          line-height: 1.5;
          text-align: center;
        }
        .p1 {
          font-size: 16px;
          // background: rgba(0, 0, 0, 0.5);
          text-shadow: 1px 1px 1px #000;
        }
        .p2 {
          font-size: 12px;
          // background: rgba(0, 0, 0, 0.5);
          text-shadow: 1px 1px 1px #000;
        }
      }
    }
  }
  .module-right {
    height: 100%;
  }
}
</style>